const quotes = 
    ["The more you have, the more you are occupied, the less you have, the more free you are.",
    "Simplicity is the ultimate sophistication.",
    "Less is more.",
    "Buy less, choose wisely, make it last.",
    "The greatest wealth is to live content with little.",
    "Consumption is not the answer to the question of happiness; less is often more.",
    "The best things in life aren’t things.",
    "It’s not how much we have, but how much we enjoy, that makes happiness.",
    "The less I have, the more I can appreciate what I do have.",
    "Owning less is better than organizing more.",
    "The ultimate goal of life is not to accumulate possessions, but to enrich your life with experiences.",
    "It's not the things we have in life that bring us happiness, but the people and moments we share.",
    "Simplicity is not about deprivation. It’s about creating space to live abundantly with what matters most.",
    "Materialism is the belief that having more will make you happy. It’s the belief that buying more will make your life complete.",
    "Having enough is a wealth that many overlook.",
    "The secret of happiness, you see, is not in wanting more, but in appreciating what you already have.",
    "He who buys what he does not need, steals from himself.",
    "Excessive consumption will only lead to the depletion of what we have, not more satisfaction.",
    "True simplicity means stripping away the unnecessary so that the necessary may speak.",
    "The richest person is not the one who has the most, but the one who needs the least.",
    "Don’t count your possessions, count your experiences.",
    "True freedom is not having more, but needing less.",
    "We should consume less and experience more.",
    "Material things don’t define happiness. Connection, experiences, and relationships do.",
    "You do not need to own everything in order to enjoy life.",
    "Living with less allows you to do more of what truly matters.",
    "Simplicity is the keynote of all true elegance.",
    "Living with less lets you live with more.",
    "The things we think we want are often the very things that drain our energy and steal our peace.",
    "By not consuming so much, we are creating space for more meaningful experiences and connection.",
    "The less I have, the more I am capable of being truly grateful for what I do have.",
    "We should be less concerned with what we own and more concerned with what we experience.",
    "The joy of life consists of the ability to not need to buy things, but simply to experience them.",
    "Minimalism isn’t about having less, it’s about making room for more.",
    "One of the most important things you can do is to stop accumulating.",
    "Don’t chase after possessions. Let them come to you by focusing on your real needs.",
    "The greatest gift you can give yourself is not more things, but more time.",
    "What is essential is invisible to the eye.",
    "A simple life is not necessarily a poor life; it's a life with just what you need to feel rich.",
    "Don’t measure success by what you have, but by what you can let go of.",
    "The less you want, the richer you become.",
    "Less is not a lack. It’s a fullness.",
    "Living simply is the key to happiness and sustainability.",
    "We don’t need more stuff; we need more time to experience life.",
    "When you choose to have less, you open the space for what really matters.",
    "The ultimate luxury is not more things, but more time to enjoy what you already have.",
    "True minimalism isn’t about owning fewer things, it’s about owning what matters most.",
    "The richness of life is found not in what you own, but in the experiences you cherish.",
    "Consume with intent, and let your heart be your guide.",
    "The art of living lies not in amassing possessions but in recognizing the importance of the intangible.",
    "Let go of things so you can embrace what truly matters.",
    "Not everything that is essential is for sale.",
    "Less excess, more meaning.",
    "Possessions are not the root of happiness, but simple joys and meaningful relationships are.",
    "Life is richer with less. It’s a paradox, but it’s true.",
    "What’s essential is often invisible, but it’s also the most valuable.",
    "When you stop accumulating things, you start accumulating experiences.",
    "True happiness comes from owning only what you can truly enjoy.",
    "A life without excess is a life of true luxury.",
    "Possessions are not the measure of life; living fully is.",
    "The fewer things you own, the less you have to worry about.",
    "Accumulation of things only fills your space, not your heart.",
    "Happiness doesn’t come from buying, it comes from living.",
    "You don’t have to own everything to enjoy it." ,
    "Happiness isn’t found in a shopping cart." ,
    "Let go of the need for excess and embrace a simpler life." ,
    "You don’t need to buy everything to experience joy.",
    "Letting go of material things is the first step toward freedom",
    "True contentment comes from less stuff, not more"]

export default quotes